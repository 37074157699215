import { Component } from 'vue-property-decorator';

import { getComponentProps } from '@/utils/vueUtils';
import { SwiperOptions } from '@/utils/swiperUtils';

import { CarouselPromoBlockViewModel } from '@/modules/CarouselPromoModule/CarouselPromoModule.types';

import CardCarouselPromo from '@/components/CarouselPromoCard/CarouselPromoCard.vue';
import CarouselPromoCardViewModel from '@/common/interfaces/carousel/CarouselPromoCard';

import { getResizeImage } from '@/utils/commonUtils';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';
import { SegmentElement } from '@/plugins/utm/SegmentElement';
import { getStore } from '@/store';

@Component({
  components: {
    CardCarouselPromo,
  },
  props: getComponentProps<CarouselPromoBlockViewModel>({
    Title: 'Featured Destinations',
    Subtitle: 'Featured',
    Type: 'Destination',
    Slides: [
      {
        Title: 'South Pacific & French Polynesia',
        Text: 'lorem ipsum dolor sit amet consectetur adipsing elit',
        Src: '#',
        Image: {
          Url: getResizeImage('diving.jpg'),
          Alt: 'Divers',
        },
        Price: '',
        Wishlist: true,
      },
    ],
  }),
})
export default class CarouselPromoModule extends mixins(SwiperMixin) {
  readonly p!: CarouselPromoBlockViewModel;
  wasInteracted = false;

  module: HTMLElement;
  globalAnalytics: any = {};

  get Slides() {
    return this.p.Slides.length;
  }

  onModuleInteraction() {
    if (!this.wasInteracted) {
      SegmentElement.sendEvent('moduleInteraction', {
        component_name: 'CardCarouselPromo',
        module_type_id: this.$attrs['data-segmentid'],
        module_type_name: this.$attrs.type,
        position: this.swiper.activeIndex + 1,
        interaction_type: 'carousel',
      })
      this.wasInteracted = true;
    }
  }

  get swiperOption(): SwiperOptions {
    return {
      slidesPerView: 2.5,
      spaceBetween: 10,
      loop: false,
      mousewheel: {
        forceToAxis: true,
        invert: true,
      },
      freeMode: true,
      scrollbar: {
        el: `#c-${this.uuid}-swiper-scrollbar`,
        draggable: true,
        snapOnRelease: false,
      },
      breakpoints: {
        1100: {
          slidesPerView: 1.45,
        },
        700: {
          slidesPerView: 1.1,
          mousewheel: false,
        },
      },
      on: {
        // @ts-ignore
        scrollbarDragStart: () => this.onModuleInteraction(),
         // @ts-ignore
        progress: (progress: number) => {if (progress) this.onModuleInteraction()},
      },
    };
  }

  productClicked(slide: CarouselPromoCardViewModel, position: number) {
    const module_type_id = this.module.getAttribute('data-segmentid');
    const module_type_name = this.module.getAttribute('type');
    SegmentElement.sendEvent('moduleClicked', {
      position,
      component_name: 'CardCarouselPromo',
      module_type_id,
      module_type_name,
    })
    if (slide.EntityId) {
      const props = {
        ...(slide.DestinationNames && { categories: slide.DestinationNames.map(category => ({ category })) }),
        ...(slide.Currency && { currency: slide.Currency }),
        ...(slide.Price && { price: +slide.Price.replace(/\D/g, '') }),
        product_id: slide.ProductType,
        image_url: slide.Image.Url,
        name: slide.Title,
        trip_length: slide.Days,
        position,
        brand: 'Lindblad Expeditions',
        module_type_id,
        module_type_name,
        page_type_id: this.globalAnalytics.page_type_id,
        page_type_name: this.globalAnalytics.page_type_name,
      }
      SegmentElement.sendEvent('productClicked', props);
    }
  }

  mounted() {
    this.module = this.$el.closest('*[contentlinkid]');
    this.globalAnalytics = getStore().state.Meta.Analytics;
  }
}
