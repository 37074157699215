import { Component, Vue, Prop } from 'vue-property-decorator';
import IconHeartOutlined from '@/assets/icons/heart-outlined.svg?inline';

@Component({
  components: {
    IconHeartOutlined,
  },
})
export default class ButtonWishlist extends Vue {
  @Prop({
    default: false,
  })
  isActive!: boolean;

  toggleActive(): void {
    this.$emit('click');
  }
}
