import { Component, Vue } from 'vue-property-decorator';

const BadgeLabelProps = Vue.extend({
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'span',
    },
  },
});
@Component({})
export default class BadgeLabel extends BadgeLabelProps {}
